import axios from "axios";

const store = {
  namespaced: true,

  state: {
    loadingConfigInfo: false,
    loadingSiteForm: false,
    loadingConfigData: false,
    global_loading: false,
    site: {},
    siteList: [],
    inventoryType: null,
    abTestCreatorPermission: false,
    legacyTestPresent: true,
    revisionsList: [],
    revision: null,
    standardSizes: [],
    copyRevisionList: [],
    ad_declarations: [],
    ab_test_revisions: [],
    tagManagerCurrencies: [],
    verificationChecklist: [],
    verificationUserId: null,
    verificationUserEmail: null,
    verificationDate: null,
    verification_check: false,
    read_only: false,
    selected_publisher: {},
  },

  getters: {
    getSelectedPublisher: state => state.selected_publisher,
    getGlobalLoading: state => state.global_loading,
    getLoadingConfigInfo: state => {
      return state.loadingConfigInfo;
    },
    getLoadingSiteForm: state => {
      return state.loadingSiteForm;
    },
    getLoadingConfigData: state => {
      return state.loadingConfigData || state.loadingConfigInfo;
    },
    getSite: state => {
      return state.site;
    },
    getSitesList: state => {
      return state.siteList;
    },
    getInventoryType: state => {
      return state.inventoryType;
    },
    getLegacyTestPresent: state => {
      return state.legacyTestPresent;
    },
    getABTestCreatorPermission: state => {
      return state.abTestCreatorPermission;
    },
    getRevisionsList: state => {
      return state.revisionsList;
    },
    getCopyRevisionsList: state => {
      return state.copyRevisionList;
    },
    getRevision: state => {
      return state.revision;
    },
    getABTestRevisions: state => {
      return state.ab_test_revisions;
    },
    getConfigStatus: (state, getters, rootState, rootGetters) => {
      return rootGetters["siteWebConfig/getConfigStatus"];
    },
    isConfigEditable: (state, getters) => {
      return getters.getConfigStatus === "modified";
    },
    isReadOnly: state => {
      return state.read_only;
    },
    getNewestRevision: state => {
      return state.revisionsList[0];
    },
    getPrereleasedRevision: state => {
      const index = state.revisionsList.findIndex(
        r => r?.status === "prerelease"
      );
      return state.revisionsList[index >= 0 ? index : 0];
    },
    getInventoryTypeList: () => () => {
      return ["WEB", "AMP"];
    },
    getStandardSizes: state => {
      return state.standardSizes;
    },
    getSiteEntities: (state, getters, rootState, rootGetters) => {
      let sitesWithDefaultConfig = [];
      sitesWithDefaultConfig = [...rootGetters["siteEntities/getSiteEntities"]];
      sitesWithDefaultConfig.push({
        id: null,
        name: "Default Config",
        url: "Default Config",
        gam_ad_unit_name: "default-config.com",
        publisher_id: null,
      });
      return sitesWithDefaultConfig;
    },
    getAdDeclarations: state => {
      return state.ad_declarations.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    },
    getTagManagerCurrencies: state => {
      return state.tagManagerCurrencies;
    },
    getVerificationChecklist: state => {
      return state.verificationChecklist;
    },
    getVerificationUser: state => {
      return {
        id: state.verificationUserId,
        email: state.verificationUserEmail,
      };
    },
    getVerificationDate: state => {
      return state.verificationDate;
    },
    getVerificationCheck: state => {
      return state.verification_check;
    },
  },

  mutations: {
    setGlobalLoading(state, loading) {
      state.global_loading = loading;
    },
    setLoadingConfigInfo(state, loading) {
      state.loadingConfigInfo = loading;
    },
    setLoadingSiteForm(state, loading) {
      state.loadingSiteForm = loading;
    },
    setLoadingConfigData(state, loading) {
      state.loadingConfigData = loading;
    },
    setSite(state, site) {
      state.site = site;
    },
    setReadOnly(state, value) {
      state.read_only = value;
    },
    setSelectedPublisher(state, value) {
      state.selected_publisher = value;
    },
    setSiteList(state, siteList) {
      state.siteList = siteList;
    },
    setRevisionsList(state, revisionsList) {
      state.revisionsList = revisionsList.sort((a, b) => b.number - a.number);
    },
    setRevision(state, data) {
      if (data) {
        state.revision = {
          number: data.revision ? data.revision : data.number,
          status: data.status,
          name: data.name,
          ab_test_enabled: data.ab_test_enabled,
          ab_test_sampling: data.ab_test_sampling,
          ab_test_main: data.ab_test_main,
        };
      }
    },
    setInventoryType(state, inventoryType) {
      state.inventoryType = inventoryType;
    },
    setLegacyTestPresent(state, legacyTestPresent) {
      state.legacyTestPresent = legacyTestPresent;
    },
    setABTestCreatorPermission(state, abTestCreatorPermission) {
      state.abTestCreatorPermission = abTestCreatorPermission;
    },
    setStandardSizes(state, standardSizes) {
      state.standardSizes = standardSizes;
    },
    setAdDeclarations(state, adDeclarations) {
      state.ad_declarations = adDeclarations;
    },
    setABTestRevisions(state, revisions) {
      state.ab_test_revisions = revisions;
    },
    setTagManagerCurrencies(state, currencies) {
      state.tagManagerCurrencies = currencies;
    },
    setVerificationChecklistFromModel(state, checkPointsList) {
      state.verificationChecklist = checkPointsList;
    },
    setVerificationChecklistFromAPI(state, data) {
      state.verificationChecklist = [...data.checklist];
      state.verificationUserId = data.verification_user_id || null;
      state.verificationUserEmail = data.verification_user_email || null;
      state.verificationDate = data.verification_date || null;
      // state.check = data.check || false;
      state.verification_check = data.verification_check || false;
    },
  },

  actions: {
    async fetchSiteConfigInfo({ commit, state }) {
      commit("setLoadingConfigInfo", true);
      await axios
        .get("/tags_manager/config_info", {
          params: {
            site_id: state.site.id,
            inventory_type: state.inventoryType,
          },
        })
        .then(response => {
          if (response.data.revisions.length === 0) {
            commit("setRevisionsList", []);
          } else {
            commit("setRevisionsList", response.data.revisions);
          }
          commit("setStandardSizes", response.data.standard_sizes);
          commit(
            "setTagManagerCurrencies",
            response.data.tag_manager_currencies
          );
          commit("setAdDeclarations", response.data.ad_declarations);
          commit("setABTestRevisions", response.data.ab_test_revisions);
          commit(
            "setABTestCreatorPermission",
            response.data.ab_test_creator_permission
          );
          commit("setLegacyTestPresent", response.data.legacy_test_present);
          commit("setLoadingConfigInfo", false);
        });
    },
    async endABTest({ commit }, payload) {
      commit("setLoadingConfigInfo", true);
      await axios
        .post("/tags_manager/select_winner_test", null, {
          params: payload,
        })
        .then(response => commit("setRevision", response.data.data));
      commit("setLoadingConfigInfo", false);
    },
    async createConfig({ commit }, payload) {
      commit("setLoadingConfigInfo", true);
      await axios
        .post("/site_web_config/create_config", null, {
          params: payload,
        })
        .then(response => commit("setRevision", response.data.data));
      commit("setLoadingConfigInfo", false);
    },
    async updateSiteAndInventoryInStore({ commit }, payload) {
      commit("setSite", payload.site);
      commit("setInventoryType", payload.inventoryType);
    },
    async updateConfig({ commit, state, rootState }) {
      commit("setLoadingConfigInfo", true);
      const updated_by = rootState.context.profile.email;
      const data = {
        site_web_config: { ...rootState.siteWebConfig.webConfig, updated_by },
        adslot_web_config_list: rootState.adslotWebConfig.adslotWebConfig,
        adslot_web_bidder_list: rootState.adslotWebBidder.adslotWebBidder,
        web_bidder_list: rootState.adslotWebBidder.webBidder,
      };
      await axios
        .post("/tags_manager/config", data, {
          params: {
            site_id: state.site.id,
            inventory_type: state.inventoryType,
            revision: state.revision.number,
          },
        })
        .then(response => commit("setRevision", response.data.site_web_config));
    },
    async fetchVerificationChecklist({ commit, state }) {
      commit("setLoadingConfigInfo", true);
      commit("setGlobalLoading", true);
      await axios
        .get("/tags_manager/checklist", {
          params: {
            se_id: state.site.id,
            inventory_type: state.inventoryType,
            revision: state.revision.number,
          },
        })
        .then(response => {
          commit("setVerificationChecklistFromAPI", response.data.data);
          commit("setLoadingConfigInfo", false);
          commit("setGlobalLoading", false);
        });
    },
    async updateVerificationChecklist({ commit, state, rootState }) {
      commit("setLoadingConfigInfo", true);
      const data = {
        site_web_config_id: rootState.siteWebConfig.webConfig.id,
        checklist: state.verificationChecklist,
        verification_user_id: rootState.context.profile.user_id,
        verification_user_email: rootState.context.profile.email,
      };
      await axios
        .post("/tags_manager/checklist", data, {
          params: {
            se_id: state.site.id,
            inventory_type: state.inventoryType,
            revision: state.revision.number,
          },
        })
        .then(response => {
          commit("setVerificationChecklistFromAPI", response.data.data);
          commit("setLoadingConfigInfo", false);
        });
    },
    async verifyConfig({ commit, state, rootState }) {
      commit("setLoadingConfigInfo", true);
      const data = {
        site_web_config_id: rootState.siteWebConfig.webConfig.id,
        checklist: state.verificationChecklist,
        verification_user_id: rootState.context.profile.user_id,
        verification_user_email: rootState.context.profile.email,
      };
      await axios
        .post("/tags_manager/verify_config", data, {
          params: {
            se_id: state.site.id,
            inventory_type: state.inventoryType,
            revision: state.revision.number,
          },
        })
        .then(response => {
          commit("setVerificationChecklistFromAPI", response.data.data);
          commit("setLoadingConfigInfo", false);
        });
    },
    async fetchJsInsertionCode({ state, dispatch }) {
      let result = await axios.get("/tags_manager/js_insertion", {
        params: {
          se_id: state.site.id,
          inventory_type: state.inventoryType,
          revision: state.revision.number,
        },
      });
      if (result.status === 200) {
        dispatch("warnings/showSnackbarInfo", result.data.message, {
          root: true,
        });
        var textArea = document.createElement("textarea");
        textArea.value = result.data.js_insertion_code;

        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
          document.execCommand("copy");
          dispatch(
            "warnings/showSnackbarInfo",
            "Insertion code copied to clipboard",
            {
              root: true,
            }
          );
        } catch (err) {
          dispatch("warnings/showSnackbarError", "Unable to copy", {
            root: true,
          });
        }

        document.body.removeChild(textArea);
      } else {
        dispatch("warnings/showSnackbarError", result.data.message, {
          root: true,
        });
      }
    },
  },
};

export default store;
