<template>
  <v-row>
    <v-col cols="12">
      <v-data-table
        height="calc(100vh - 350px)"
        :loading="getLoading"
        :headers="headers"
        :search="search"
        :items="getFilteredPublishers"
        item-key="id"
        fixed-header
        :items-per-page="30"
        :footer-props="{
          'show-first-last-page': true,
          'items-per-page-options': [10, 20, 30, 50, 100],
        }"
        :class="archived ? 'grey lighten-2' : 'white'"
        hide-default-header
        :custom-filter="customFilter"
      >
        <template
          v-slot:header="{
            props: { headers, options: { sortBy, sortDesc } },
            on: { sort },
          }"
        >
          <thead>
            <tr>
              <th
                v-for="h in headers"
                :key="h.text"
                :id="h.text + '-header'"
                @click="(h.sortable ?? true) && sort(h.value)"
              >
                <span v-if="!h.description">
                  <v-icon
                    small
                    v-if="(h.sortable ?? true) && h.value === sortBy[0]"
                  >
                    {{ sortDesc[0] ? "mdi-arrow-up" : "mdi-arrow-down" }}
                  </v-icon>
                  {{ h.text }}
                </span>
                <v-tooltip bottom v-if="h.description">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" light color="#000" small class="help-mark"
                      >mdi-help-circle-outline</v-icon
                    >
                    <span v-on="on">
                      <v-icon
                        small
                        v-if="(h.sortable ?? true) && h.value === sortBy[0]"
                      >
                        {{ sortDesc[0] ? "mdi-arrow-up" : "mdi-arrow-down" }}
                      </v-icon>
                      {{ h.text + " " }}
                    </span>
                  </template>
                  <span v-html="h.description" />
                </v-tooltip>
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:top>
          <v-row><v-divider /></v-row>
          <v-row align="center" justify="space-around" class="white">
            <v-col cols="3" class="text-center">
              <v-btn
                color="primary"
                v-on:click="addPublisher(getDefaultPublisher())"
                >{{ $t("buttons.publishers.add") }}
                <v-icon dark right>add_box</v-icon></v-btn
              >
            </v-col>
            <v-divider vertical inset />
            <v-col cols="6">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('buttons.general.search')"
                single-line
                hide-details
              />
            </v-col>
            <v-col cols="2" class="d-flex justify-end">
              <v-switch
                v-model="archived"
                v-on:change="toggleArchived"
                label="Archived"
              />
            </v-col>
          </v-row>
          <v-row><v-divider /></v-row>
          <v-row align="center" justify="space-between" class="white">
            <v-col cols="2">
              <v-card-title
                >{{ $tc("buttons.general.filter", 2) }}:</v-card-title
              >
            </v-col>
            <v-col cols="9">
              <v-row>
                <v-col
                  cols="3"
                  v-for="(filter, index) in filterList"
                  :key="index"
                >
                  <v-chip color="primary">
                    <v-text-field
                      dark
                      v-model="filter.search"
                      :label="filter.label"
                      single-line
                    />
                    <v-icon @click="removeFilterItem(filter, index)">
                      close
                    </v-icon>
                  </v-chip>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="1">
              <v-menu bottom left offset-x>
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" v-on="on">
                    <v-icon dark> filter_list </v-icon>
                  </v-btn>
                </template>
                <v-list color="primary" dark>
                  <v-list-item
                    v-for="(item, index) in getFiltersList"
                    :key="index"
                    @click="addFilterItem(item)"
                  >
                    <v-list-item-title>{{ item.label }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
          <v-row><v-divider /></v-row>
        </template>
        <template v-slot:item.sales_manager_user_id="{ item }">
          {{ formatAgentEmailById({ id: item.sales_manager_user_id }) }}
        </template>
        <template v-slot:item.agent_user_id="{ item }">
          {{ formatAgentEmailById({ id: item.agent_user_id }) }}
        </template>
        <template v-slot:item.active="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-if="item.active === true" color="green" v-on="on"
                >mdi-check</v-icon
              >
              <v-icon v-else-if="item.active === false" color="gray" v-on="on"
                >mdi-close-octagon-outline</v-icon
              >
            </template>
            <span>{{ item.active ? "Active" : "Inactive" }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.mcm_manage_inventory="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                v-if="item.mcm_manage_inventory === true"
                color="green"
                v-on="on"
                >mdi-check</v-icon
              >
              <v-icon
                v-else-if="item.mcm_manage_inventory === false"
                color="gray"
                v-on="on"
                >mdi-close-octagon-outline</v-icon
              >
            </template>
            <span>{{ item.mcm_manage_inventory ? "Active" : "Inactive" }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.action="{ item }">
          <div style="white-space: nowrap">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn depressed icon v-on="on" @click="openDialog(item)">
                  <v-icon small> edit </v-icon>
                </v-btn>
              </template>
              <span>Edit Publisher</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  icon
                  v-on="on"
                  :href="item.data_studio_url"
                  target="_blank"
                >
                  <v-icon
                    small
                    :color="linkColor(item.data_studio_url || false)"
                  >
                    signal_cellular_alt
                  </v-icon>
                </v-btn>
              </template>
              <span>Data Studio Link</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  icon
                  v-on="on"
                  :href="`${gamUrl}${item.gam_network_id}#home`"
                  target="_blank"
                >
                  <img
                    src="../../../../public/admanager-logo.png"
                    alt=""
                    height="15"
                  />
                </v-btn>
              </template>
              <span>Google Ad Manager</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  icon
                  @click="openDialog(item, { readOnly: true })"
                  v-on="on"
                >
                  <v-icon small> visibility </v-icon>
                </v-btn>
              </template>
              <span>Overview Publisher</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn depressed icon v-on="on" @click="addSite(item)">
                  <v-icon small> add </v-icon>
                </v-btn>
              </template>
              <span>Add Site</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn depressed icon @click="addDPAConfig(item)" v-on="on">
                  <v-icon small> settings </v-icon>
                </v-btn>
              </template>
              <span>Demand Partner Accounts</span>
            </v-tooltip>
            <v-tooltip bottom v-if="!archived">
              <template v-slot:activator="{ on }">
                <v-btn depressed icon @click="onArchive(item)" v-on="on">
                  <v-icon small color="error"> delete </v-icon>
                </v-btn>
              </template>
              <span>Archive publisher</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
      <PublisherDialog
        ref="publishers_dialog"
        v-bind:edited-item="editedItem"
        v-bind:edited-index="editedIndex"
        v-bind:gam_network_name="getGAMNetworkName"
      />
      <SiteDialog
        v-bind:edited-site="editedSiteItem"
        v-bind:is-site-new="true"
        v-bind:read-only="false"
        v-bind:show="siteDialogShow"
        v-bind:close="closeSiteEntityDialog"
        v-bind:disable-publisher-select="true"
      />
      <PublisherDemandPartnerDialog
        ref="publisher_demand_partner_dialog"
        :publisher="editedItem"
      />
      <ConfirmationDialog
        :confirmation-dialog="confirmationDialog"
        :on-confirm="confirmArchive"
        :on-close="closeArchive"
        msg="Do you want to archive publisher?"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import store from "./../../../store/index";
import SiteDialog from "../Sites/SiteDialog";
import PublisherDialog from "./PublisherDialog";
import PublisherDemandPartnerDialog from "./PublisherDemandParnerDialog";
import { filterMixin } from "../../../helpers/filterMixin";
import ConfirmationDialog from "@/components/Helpers/ConfirmationDialog";

export default {
  name: "PublishersTable",
  components: {
    SiteDialog,
    PublisherDialog,
    PublisherDemandPartnerDialog,
    ConfirmationDialog,
  },
  mixins: [filterMixin],
  data() {
    return {
      dialog: false,
      confirmationDialog: false,
      itemToRemove: {},
      readOnly: false,
      valid: true,
      search: "",
      editedIndex: -1,
      editedItem: {},
      editedSiteItem: {},
      siteDialogShow: false,
      filterMenuEnabled: false,
      filterList: [],
      archived: false,
      filterOptions: [
        {
          filterField: "id",
          label: "ID",
          search: "",
          enabled: false,
        },
        {
          filterField: "name",
          label: "Name",
          search: "",
          enabled: false,
        },
        {
          filterField: "gam_network_id",
          label: "GAM Network ID",
          search: "",
          enabled: false,
        },
        {
          filterField: "company_name",
          label: "Company Name",
          search: "",
          enabled: false,
        },
        {
          filterField: "sales_manager_user_id",
          label: "Sales account manager",
          search: "",
          enabled: false,
          custom_filter: ({ element, item }) => {
            return this.filterAgentsFrom({
              element,
              field: item.sales_manager_user_id,
            });
          },
        },
        {
          filterField: "agent_user_id",
          label: "Account manager",
          search: "",
          enabled: false,
          custom_filter: ({ element, item }) => {
            return this.filterAgentsFrom({
              element,
              field: item.agent_user_id,
            });
          },
        },
      ],
      headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Reports",
          value: "active",
        },
        {
          text: "MCM Manage Inventory",
          value: "mcm_manage_inventory",
        },
        {
          text: "GAM Network ID",
          value: "gam_network_id",
        },
        {
          text: "Company Name",
          value: "company_name",
        },
        {
          text: "Account manager",
          value: "agent_user_id",
        },
        {
          text: "Sales account manager",
          value: "sales_manager_user_id",
        },
        {
          text: "Action",
          value: "action",
          sortable: false,
          filterable: false,
          align: "center",
        },
      ],
      gamUrl: "https://admanager.google.com/",
    };
  },
  mounted() {
    store.dispatch("context/fetchAgents");
    store.dispatch("publishers/fetchPublishers");
    store.dispatch("publishers/getPennylaneClients");
  },
  computed: {
    getFilteredPublishers() {
      return this.getPublishers.filter(this.filterItem);
    },
    ...mapGetters("publishers", [
      "getLoading",
      "getPublishers",
      "getDefaultPublisher",
      "getGAMNetworkName",
    ]),
    ...mapGetters("siteEntities", ["getDefaultSiteEntity"]),
    ...mapGetters("context", ["getAgents"]),
  },
  methods: {
    ...mapActions("publishers", [
      "fetchPublishers",
      "deletePublisher",
      "fetchPublisherCountries",
      "fetchAllCountries",
    ]),
    ...mapMutations("publishers", [
      "setPennylaneMappingPublisher",
      "setPublisherCountries",
    ]),
    addPublisher(item) {
      this.editedIndex = -1;
      this.editedItem = { ...item };
      this.$refs.publishers_dialog.readOnly = false;
      this.$refs.publishers_dialog.show = true;
      this.setPublisherCountries({});
      this.fetchAllCountries();
    },
    openDialog(item, { readOnly = false } = {}) {
      this.editedIndex = this.getPublishers.indexOf(item);
      this.editedItem = { ...item };

      this.editedItem?.publisher_code &&
        this.fetchPublisherCountries(this.editedItem.publisher_code);
      this.fetchAllCountries();

      this.setPennylaneMappingPublisher({
        customer: {
          pennylane_source_id: null,
        },
        supplier: {
          pennylane_source_id: null,
        },
      });

      this.editedItem.gam_network_id?.trim?.() &&
        store.dispatch("publishers/getGAMNetworkName", item.id);

      store.dispatch(
        "publishers/getPennylaneClientMappingByPublisher",
        item.id
      );
      this.$refs.publishers_dialog.readOnly = readOnly;
      this.$refs.publishers_dialog.show = true;
    },
    addSite(item) {
      this.editedSiteItem = this.getDefaultSiteEntity();
      this.editedSiteItem.publisher_id = item.id;
      this.siteDialogShow = true;
    },
    closeSiteEntityDialog() {
      this.siteDialogShow = false;
    },
    addDPAConfig(item) {
      store.dispatch("publishers/selectPublisher", item);
      store.dispatch("publishers/fetchPDPAForPublisher");
      this.$refs.publisher_demand_partner_dialog.readOnly = false;
      this.$refs.publisher_demand_partner_dialog.show = true;
      this.$refs.publisher_demand_partner_dialog.disable_select = true;
    },
    onArchive(item) {
      this.itemToRemove = item;
      this.confirmationDialog = true;
    },
    confirmArchive() {
      this.deletePublisher(this.itemToRemove);
      this.confirmationDialog = false;
    },
    closeArchive() {
      this.confirmationDialog = false;
    },
    linkColor(param = false) {
      if (param) return "blue";
      else return "grey";
    },
    toggleArchived() {
      this.search = "";
      this.fetchPublishers(this.archived);
    },
    findAgentsIdByEmail({ google_name }) {
      if (this.getAgents?.length === 0) store.dispatch("context/fetchAgents");
      return this.getAgents
        .filter(({ google_name: r_google_name }) =>
          r_google_name?.toLowerCase?.()?.includes?.(google_name.toLowerCase())
        )
        .map(({ id }) => id);
    },
    formatAgentEmailById({ id }) {
      if (this.getAgents?.length === 0) store.dispatch("context/fetchAgents");
      const [agent] = this.getAgents.filter(({ id: r_id }) => r_id === id);
      return agent?.google_name;
    },
    filterAgentsFrom({ element, field }) {
      if (!element.search) return true;
      const agents = this.findAgentsIdByEmail({
        google_name: element.search,
      });

      return agents.includes(field);
    },
    customFilter(value, search, item) {
      return (
        `${item.id}`.includes(search) ||
        value?.toLowerCase?.().includes?.(search.toLowerCase()) ||
        value === search ||
        this.filterAgentsFrom({
          element: { search },
          field: item.agent_user_id,
        }) ||
        this.filterAgentsFrom({
          element: { search },
          field: item.sales_manager_user_id,
        })
      );
    },
  },
};
</script>

<style scoped></style>
